import React, { useEffect, useRef } from "react";

const Recommendations = ({ template, searchUrl, resultText }) => {
  const recommendationsContainerRef = useRef(null)
  useEffect(() => {
    const container = recommendationsContainerRef.current;
    if (!container) {
      return;
    }
    $(container).find(".image-container.tile-slider").each(function () {
      var $container = $(this);
      if ($container.find("img").length > 1) {
        $container.slick({
          dots: true,
          dotsClass: "slick-dots tile-dots",
          prevArrow:
            '<button type="button" class="slick-prev tile-slick-arrow tile-prev">Previous</button>',
          nextArrow:
            '<button type="button" class="slick-next tile-slick-arrow tile-next">Next</button>',
        });
      } else {
        $container.css("display", "flex");
        $container.find("img").css("align-self", "center");
      }
    });
    $(".image-container img").removeClass("d-none");
  }, [template]);

  return (
    <div ref={recommendationsContainerRef}>
      {searchUrl ? (
        <div className="recommendations-container products-main-block search-results">
          <h1>We’ve found {resultText} Perfect Match options for you: </h1>
          <p>
            If these RVs are not located near you, don’t worry, we’re pros at
            making long distance relationships work!
          </p>
          <div className="product-grids-block">
            <div
              className="matching-recommendations row product-grid"
              dangerouslySetInnerHTML={{ __html: template }}
            ></div>
          </div>
          <p>
            None of these are “The One”? See more RVs that match your needs <a href={searchUrl}><u>here</u></a>
          </p>
        </div>
      ) : (
        <div dangerouslySetInnerHTML={{ __html: template }}></div>
      )}
    </div>
  );
};
export default Recommendations;

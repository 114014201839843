import React, {useState, useEffect} from "react";
import ImageText from "./ImageText";
import TileButton from "./TileButton";

const QuizOption = ({ type, options, selectAnswer, onSubmit, keyName, singleSelect, answer, answers, customInput, setIsButtonDisabled }) => {
  
  switch (type) {

    case 'email':
    const checkboxes = options.reduce((acc, opt) => {
      acc[opt.value] = answers[opt.value] || true;
      return acc;
    }, {});
    const [valid, setValid] = useState(answers.email ? /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(answers.email) : false);
    const [checkedValues, setCheckedValues] = useState({...checkboxes});
    const [userEmail, setUserEmail] = useState(answers.email || '');

    const handleChange = (email) => {
        const isValid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
        setUserEmail(email);
        setValid(isValid);
    }
    const saveCheckbox = (e, value) => {
      const isChecked = e.target.checked;
      setCheckedValues((prev) => ({
        ...prev,
        [value]: isChecked,
      }));
    };
    const handleSubmit = (email = userEmail, values = checkedValues) => {
      values.email = email;
      onSubmit(values, 'formValues');
    };
    return (
      <div className="w-50 m-auto">
        <input placeholder="Email Address" name="email" type="text" value={userEmail} onChange={e => handleChange(e.target.value)}></input>
        {options.map(({ text, value }) => (
        <label className="checkbox">
          <input value={value} checked={checkedValues[value]} type="checkbox" onChange={(e) => saveCheckbox(e, value)}></input>
          {text}
        </label>
        ))}
        {(userEmail.length > 1 && !valid && <p>Email is invalid</p>) }
        <button className="quiz-button" disabled={!valid} onClick={() => handleSubmit()}>Get Results</button>
      </div>
    );
    
    case 'connectedRadio':
      var widthClass = options.length > 4 ? 'w-75' : 'w-50'
    return (
      <div className={`connected-radio d-flex ${widthClass} justify-content-between m-auto`}>
        {options.map(({ text, value, }) => (
        <div>
            <input
              type="radio"
              checked={answer === value}
              value={value}
              onChange={() => selectAnswer(value)}
            />
            <span>
              {text}
            </span>        
        </div>
        ))}
      </div>
    );
    
    case 'imageText':
      let imageTextStyles = {
        width: `${Math.floor(100/options.length) - 3}%`
      };
      const [selectedImageValues, setSelectedImageValues] = useState(answer.trim() !== '' ? answer.split('|') : []);

      useEffect(() => {
        selectAnswer(selectedImageValues.join('|'));
      }, [selectedImageValues]);
    return (
      <div className="d-flex w-75 justify-content-between m-auto text-center image-text-contaiter">
        {options.map(({ text, value, image }) => (
          <ImageText 
            styles={imageTextStyles}
            singleSelect={singleSelect}
            onSubmit={onSubmit}
            setSelectedValues={setSelectedImageValues}
            text={text}
            image={image}
            value={value}
            keyName={keyName}
            selectedValues={selectedImageValues}
        />
        ))}
      </div>
    );
    
    case 'tileButtons':
    let styles = {
      width: `${Math.floor(100/options.length) - 3}%`
    };
    
    const [selectedValues, setSelectedValues] = useState(answer.trim() !== '' ? answer.split('|') : []);
    const isCustomValue = customInput && (parseFloat(selectedValues) !== 0 && !isNaN(parseFloat(selectedValues)));
    const [error, setError] = useState(false);
    
    const handleCustomInput = value => {
      var isNumber =  value !== '' ? /^[0-9]+$/.test(value) : false;
      if(!isNumber) {
        setIsButtonDisabled(true);
        setError(true);
        setSelectedValues([value]);
      } else {
        setIsButtonDisabled(false);
        setError(false);
        setSelectedValues([value]);
      }
    };
    
    useEffect(() => {
      selectAnswer(selectedValues.join('|'));
    }, [selectedValues]);

    return (
      <>
        <div className="tile-buttons-block d-flex w-75 justify-content-between m-auto text-center ">
          {options.map(({ text, supportText, value, image }) => (
            <TileButton 
              styles={styles}
              singleSelect={singleSelect}
              onSubmit={onSubmit}
              setSelectedValues={setSelectedValues}
              supportText={supportText}
              text={text}
              image={image}
              value={value}
              keyName={keyName}
              selectedValues={isCustomValue ? [] : selectedValues}
            />
          ))}
        </div>
        {customInput &&
          <div className="w-75 text-center m-auto">
            <p>{customInput.text}</p>
            <input className="w-25" value={isCustomValue ? selectedValues[0] : ''} placeholder={customInput.placeholder} type="text" onChange={(e) => handleCustomInput(e.target.value)}></input>
            {error && 
              <div className="text-danger">Only numbers are allowed</div>
            }
          </div>
          }
        </>
    );

    case 'simpleButtons':

    return (
      <div className="d-flex w-50 justify-content-between m-auto simple-buttons">
        {options.map(({ text, value }) => (
          <div>
            <button className="quiz-option quiz-button" value={value} onClick={() => onSubmit(value, keyName)}>{text}</button>
          </div>
        ))}
      </div>
    );

    case 'radio':
      return (
        <div className="m-auto d-flex justify-content-center m-auto q-radio">
          <div>
          {options.slice(0, 6).map(({ text, value }) => (
            <label className="">
              <input checked={answer === value} name={value} value={value} type="radio" onChange={() => selectAnswer(value)}></input>
              {text}
              <br />
            </label>
          ))}
          </div>
          <div className="">
            {options.slice(6).map(({ text, value }) => (
              <label>
                <input className="location" checked={answer === value} name={value} value={value} type="radio"  onChange={() => selectAnswer(value)}></input>
                {text}
                <br />
              </label>
            ))}
          </div>
        </div>
      );
  
    default:
      const [fName, lName] = answer ? answer.split(',') : [];
      const [firstName, setFirstName] = useState(fName || '');
      const [lastName, setLastName] = useState(lName || '');
    
      const handleFirstNameChange = (e) => {
        const newFirstName = e.target.value;
        setFirstName(newFirstName);
        const nameValue = `${newFirstName},${lastName}`;
        selectAnswer(nameValue === ',' ? '' : nameValue);
      };
    
      const handleLastNameChange = (e) => {
        const newLastName = e.target.value;
        setLastName(newLastName);
        const nameValue = `${firstName},${newLastName}`;
        selectAnswer(nameValue === ',' ? '' : nameValue);
      };
      return (
        <div className="w-50 m-auto d-flex flex-column align-items-center">
          <input value={firstName} placeholder="What is your first name?" type="text" onChange={handleFirstNameChange}></input>
          <input value={lastName} placeholder="What is your last name?" type="text" onChange={handleLastNameChange}></input>
        </div>
      );   
  }
};

export default QuizOption;
